import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useToast } from 'vue-toastification';
import MainLayout from '@/components/common/MainLayout.vue';
import AuthLayout from '@/components/common/AuthLayout.vue';
import i18n from '@/i18n';
import authService from '@/services/authService';
import store from '@/store';
import { Profile } from '@/interfaces/User';

// Define the route records
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: AuthLayout,
    children: [
      { path: 'login', name: 'Login', component: () => import('@/views/auth/LoginView.vue'), meta: { requiresAuth: false, title: 'page_titles.login' } },
    ]
  },
  { path: '/restricted', name: 'Restricted', component: () => import('@/views/RestrictedView.vue'), meta: { title: 'page_titles.restricted' } },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: () => import('@/views/NotFoundView.vue'), meta: { title: 'page_titles.not_found' } },
  {
    path: '',
    component: MainLayout,
    meta: { requiresAuth: true },
    children: [
      { path: '', name: 'Home', component: () => import('@/views/teachers/ExamList.vue'), meta: { title: 'page_titles.my_exams' } },
      { path: '/exams/:id/preview', name: 'PreviewExam', component: () => import('@/views/teachers/PreviewExam.vue'), props: true, meta: { title: 'page_titles.preview_exam' } },
    ]
  }
];

// Create the router instance and pass the `routes` option
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

/**
 * Navigation guard to check if the user is authenticated before each route change.
 * @param to - the target Route object being navigated to
 * @param from - the current route being navigated away from
 * @param next - a function that must be called to resolve the hook
 */
router.beforeEach(async (to, from, next) => {
  const { t } = i18n.global;
  const toast = useToast();

  const pageTitle = to.meta.title ? t(to.meta.title as string) : t('site.title');
  document.title = `${pageTitle} | ${t('site.title')}`;

  if (to.meta.requiresAuth !== false) {
    try {
      let storedUserProfile: Profile | null = null;

      const storedUserProfileString = localStorage.getItem('me'); // This is a string | null
      if (storedUserProfileString) {
        // Parse the string from localStorage into an object
        try {
          storedUserProfile = JSON.parse(storedUserProfileString) as Profile;
        } catch (e) {
          console.error('Failed to parse user profile from localStorage', e);
          await authService.logout();
          next({ name: 'Login' });
          return;
        }
      } else {
        // Fetch profile from the API if not in localStorage
        storedUserProfile = await authService.getProfile();

        if (!storedUserProfile) {
          // Handle the case where profile is null after API call
          await authService.logout();
          next({ name: 'Login' });
          return;
        }
      }

      const userProfile = storedUserProfile; // At this point, userProfile is a valid Profile object

      // Dispatch user to Vuex
      store.dispatch('currentUser/updateUser', userProfile?.user);
      store.dispatch('currentUser/updateTeacherProfile', userProfile?.teacher);

      const basePermission = 'com.ltm.permissions.teachers.can.view.mock.exams'; // Base permission to access the system in the current scope.
      const requiredPermission = to.meta.permission;
      const hasBasePermission = userProfile?.user?.permissions.some((p: any) => p.permission === basePermission) && userProfile?.teacher?.status;

      if (!hasBasePermission) { // If the user does not have the base permission, logout and redirect to login page.
        await authService.logout();
        toast.error(t('user.login.permission_denied'));
        next({ name: 'Login' });
        return;
      }

      if (requiredPermission && !userProfile?.user?.permissions.some((p: any) => p.permission === requiredPermission)) {
        next({ name: 'Restricted' });
        return;
      }

      next();
    } catch (error) {
      next({ name: 'Login' });
    }
  } else {
    next();
  }
});



export default router;